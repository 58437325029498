<template>
    <div style="min-height:200px">
        <b-card>
            <app-view-table title="Api" :loading="loading_list" :isTitleTotal="true" :filters.sync="filters" :items="items" :isDropdownMenu="false" :fields="fields" :filterOptions="status_options" :isSearch="true" :isFilterOptions="true" :customColumns="custom_columns" v-on:onRowClicked="openDetails">
                <template v-slot:description="cell">
                    <div v-if="description_type == 2">
                        <span v-if="cell.data.item.status_name">{{trans('status',3)}}: {{cell.data.item.status_name}};</span> 
                        <span v-if="cell.data.item.message"> {{trans('se-message',167)}}: {{cell.data.item.message}};</span>
                        <span v-if="cell.data.item.person_name"> {{cell.data.item.person_name}};</span>
                        <span v-if="cell.data.item.person_name"> {{cell.data.item.id_person_data_main}};</span>
                    </div>
                    <div v-else>
                        {{cell.data.item.description}}
                    </div>
                </template>

                <template v-slot:row-details="row">
                        <h4 class="mb-3 mt-2">{{trans('description',182)}}</h4>
                        <b-alert variant="light" show class="p-3 mb-2 app-local-height d-block align-items-center h-100">
                            <pre>{{JSON.parse(JSON.parse(row.data.item.description))}}</pre>
                        </b-alert>
                </template>
            </app-view-table>
        </b-card>  
    </div>
</template>


<script>

// #ID - PMI 2022-02-01

import axios from "@axios";
import AppAlerts from '@core/scripts/AppAlerts';

export default {

    mounted(){
        this.onCreateThis();
    },

    data() {
        return {
            loading_list: false,
            status_options: [
                { value: 1, name: "Recruit now" },
                { value: 2, name: "Datachecker" },
            ],
            filters: {
                page: 1,
                per_page: 10,
                list_type: 1,
                filtering:{},
                sorting: {
                        field_name: null,
                        sort_direction: 0,
                        changed: 0
                },
                sortDesc: true,
                search: "",
                status: 1,
                changed: 0,
            },
            alert_class: new AppAlerts(),
            fields: [
                { key: "entry_date", label: this.trans('entry-date-on',182), thStyle: { "min-width": "200px", "max-width": "280px" }, visible: true, filtering: false },
                { key: "type_name", label: this.trans('type',182), thStyle: { "min-width": "250px", "max-width": "280px" }, visible: true, filtering: false },
                { key: "description", label: this.trans('description',182), thStyle: { "min-width": "150px", "width": "100%" }, visible: true, filtering: false, sortable: false },
            ],
            items: [],
            custom_columns: ['description'],
            description_type: 1,
        };
    },

    methods: {
        getItems() {
            this.loading_list = true;
            axios
                .post("settings/api/getList", {
                    filters: JSON.stringify(this.filters),
                })
                .then((res) => {
                    this.description_type = this.filters.list_type;
                    this.items = res.data.items;
                    this.filters.page = res.data.page;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_list = false;
                });
        },

        openDetails(item){
            if(this.filters.list_type == 2){
                this.$set(item, '_showDetails', !item._showDetails);
            }
        },


        onCreateThis(){

            this.getItems();

            this.$watch('filters.list_type', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getItems();
                } 
            });

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getItems();
                } 
            });

            this.$watch('filters.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getItems();
                } 
            });

            this.$watch('filters.search', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.filters.page = 1;
                    this.getItems();
                } 
            });

           
            this.$watch('items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.getItems();
                }   
            });

            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getItems();
                }   
            });
        }
    },
};
</script>

<style scoped></style>

